<template>
  <div>
    <div class="super">
      <div class="holder">
        <nav class="navbar  navbar-expand-lg fixed-top nav-top">
          <router-link to="dashboard/landlord">
            <a class="navLinks d-none d-md-block" href="#">
              <div class="dashboard-icon">
                <img src="../assets/images/database.svg" alt="dashboard" />
              </div>
              <span class="b2d"> Back to Dashboard</span>
            </a>
          </router-link>
          <a class="navLinks d-md-none" href="#">
            <router-link style="color:black;" to="dashboard/landlord">
              Dashboard
            </router-link>
          </a>
          <div class="logo m-auto">
            <div class="logo-div">
              <div
                class="logo-blueDiv-main"
                :class="{ hide_blueBg: scrollPosition > 50 }"
              >
                <img src="../assets/images/logo-header.svg" alt="logo" />
              </div>
              <div class="shelta-img-div" @click="backtohome()">
                <img src="../assets/images/logo1.svg" alt="" />
              </div>
            </div>
          </div>
          <a class="navLinks d-none d-md-block" href="#">
            <div class="dashboard-icon">
              <img src="../assets/images/white-settings.svg" alt="dashboard" />
            </div>
            <span class="b2d">Manage settings</span>
          </a>
          <a class="navLinks d-md-none" href="#">Settings</a>
        </nav>
        <div class="container-fluid body-wrap">
          <div class="progress-bar2">
            <div class="step">
              <p class="d-none d-md-block">Property details</p>
              <p class="d-md-none">Details</p>
              <!-- <p class=".hidden-md-up">Details</p> -->
              <div class="bullet">
                <span>1</span>
              </div>
              <div class="check fa fa-check"></div>
            </div>

            <div class="step">
              <p class="d-none d-md-block">Property location & Pricing</p>
              <p class="d-md-none">Location</p>
              <div class="bullet">
                <span>2</span>
              </div>
              <div class="check fa fa-check"></div>
            </div>

            <div class="step">
              <p class="d-none d-md-block">Documents & Media</p>
              <p class="d-md-none">Media</p>
              <div class="bullet">
                <span>3</span>
              </div>
              <div class="check check fa fa-check"></div>
            </div>

            <div class="step">
              <p>Finish</p>
              <div class="bullet">
                <span>4</span>
              </div>
              <div class="check fa fa-check"></div>
            </div>
          </div>

          <div class="form-outter">
            <form>
              <div class="page slidepage">
                <div class="container-fluid property-holder">
                  <div class="property-details">
                    <div class="details-body">
                      <div class="col-md-8 details-header">
                        <div class="details-header-text">
                          <p>Property Details</p>
                        </div>
                      </div>
                      <div class="col-md-8 details-first-section">
                        <div class="container pb-3">
                          <div class="title-wrap">
                            <div class="form-group">
                              <label class="select-label" for="title">
                                Title <span class="required_asterix">*</span>
                              </label>
                              <input
                                id="title"
                                class="form-control form-select"
                                type="text"
                                placeholder="Enter Property Title"
                                v-model="title"
                              />
                            </div>
                          </div>

                          <div class="rls col-md-8">
                            <div
                              class="col-auto my-1"
                              v-for="(business, index) in businessTypes"
                              :key="index"
                            >
                              <!-- <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                <label class="form-check-label" for="inlineRadio1">1</label>
                              </div> -->
                              <div class="custom-control custom-radio mr-sm-2">
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  :id="business.id"
                                  name="businessType"
                                  v-bind:value="business.id"
                                  v-model="businesstype"
                                />
                                <label
                                  class="custom-control-label"
                                  v-bind:for="business.id"
                                >
                                  {{ business.businesstype }}
                                </label>
                              </div>
                              <!-- {{businesstype}} -->
                            </div>
                          </div>
                          <div class="title-wrap">
                            <div class="form-group">
                              <label class="select-label" for="title">
                                Description
                                <span class="required_asterix">*</span>
                              </label>
                              <textarea
                                id="description"
                                class="form-control form-select"
                                type="text"
                                placeholder="Enter Property Description"
                                v-model="description"
                              >
                              </textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="type">
                                    Property Type
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    @change="getSubTypeInfo()"
                                    v-model="type"
                                    id="type"
                                    class="form-control form-select"
                                    name="type"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option
                                      v-for="(type, index) in proprtyTypes"
                                      :key="index"
                                      v-bind:value="type.id"
                                    >
                                      {{ type.type }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="subtype">
                                    Property Sub-type
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    v-model="subtype"
                                    id="subtype"
                                    name="subtype"
                                    class="form-control form-select"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option
                                      v-for="(subType,
                                      index) in proprtySubTypes"
                                      :key="index"
                                      v-bind:value="subType.id"
                                    >
                                      {{ subType.subtype }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="footage">
                                    Square meters
                                  </label>
                                  <input
                                    v-model="squarefootage"
                                    id="footage"
                                    class="form-control form-select"
                                    type="text"
                                    placeholder="E.g 100"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="type != '4'">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="bedrooms">
                                    No. of Bedrooms
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    v-model="bedrooms"
                                    id="bedrooms"
                                    class="form-control form-select"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>0</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" v-if="type != '4'">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="bathrooms">
                                    No. of Bathrooms
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    v-model="bathrooms"
                                    id="bathrooms"
                                    class="form-control form-select"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>0</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="type != '4'">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="toilets">
                                    No. of Toilets
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    v-model="toilets"
                                    id="toilets"
                                    class="form-control form-select"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>0</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="type != '4'">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="status">
                                    Max Parking Space
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    id="parking"
                                    class="form-control form-select"
                                    v-model="parking"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>0</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div v-if="businesstype === 7" class="col-md-6">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="duration">
                                    Mortgage Duration (in years)
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    v-model="duration"
                                    id="duration"
                                    class="form-control form-select"
                                    @change="test"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="status">
                                    Status of Property
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    id="status"
                                    class="form-control form-select"
                                    v-model="status"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option
                                      v-for="(status, index) in propStatus"
                                      :key="index"
                                      v-bind:value="status"
                                    >
                                      {{ status }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="mode">
                                    Mode of Acquisition
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <select
                                    v-model="modeofaquisition"
                                    id="mode"
                                    name="mode"
                                    class="form-control form-select"
                                  >
                                    <option value="" selected disabled>
                                      --select--
                                    </option>
                                    <option
                                      v-for="(moa, index) in mode"
                                      :key="index"
                                      v-bind:value="moa"
                                    >
                                      {{ moa }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="type != '4'">
                              <div class="form-wrap">
                                <div class="form-group">
                                  <label class="select-label" for="developer">
                                    Developer
                                    <span class="required_asterix">*</span>
                                  </label>
                                  <input
                                    v-model="developer"
                                    id="developer"
                                    class="form-control form-select"
                                    type="text"
                                    placeholder="E.g Brains and hammer"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 details-second-section">
                        <div class="facility-checkbox">
                          <div class="container">
                            <div class="row">
                              <!-- col-md-12 -->
                              <div class="">
                                <p
                                  class="feature-text"
                                  @click="features = !features"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Click"
                                >
                                  Select Property Facilities
                                  <i
                                    class="fa fa-arrow-down"
                                    :class="features ? 'arrow_up' : 'bounce'"
                                  ></i>
                                </p>
                                <div class="check-box-facility" v-if="features">
                                  <div
                                    class="checkbox-wrap"
                                    v-for="(facility, index) in facilitiesTypes"
                                    :key="index"
                                  >
                                    <div
                                      class="custom-control custom-checkbox mb-3"
                                    >
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :id="facility"
                                        name="facility"
                                        v-bind:value="facility"
                                        v-model="facilities"
                                      />
                                      <label
                                        class="custom-control-label facility-check"
                                        :for="facility"
                                      >
                                        {{ facility }}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-md-4 newfacility_div"
                                  v-if="features"
                                >
                                  <input
                                    class="form-control new_feature"
                                    v-model="newfacility"
                                    type="text"
                                    placeholder="Add facilities if not avalaible"
                                  />
                                  <button
                                    v-if="newfacility"
                                    ref="newF"
                                    @click.prevent="addNewFacility()"
                                    class="btn newfacility_btn"
                                  >
                                    Add
                                    <i
                                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                      v-if="loader"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="nextBtn propertyBtn col-md-10">
                        <button
                          @click.prevent="firstNextBtn()"
                          class="btn"
                          id="fb"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page">
                <div class="property-location">
                  <div class="details-body">
                    <div class="col-md-8 details-header">
                      <div class="details-header-text">
                        <p>Property Location & Pricing</p>
                      </div>
                    </div>
                    <div class="col-md-8 details-first-section">
                      <div class="container pb-3">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label class="select-label" for="address">
                                  Full Address of Property
                                  <span class="required_asterix">*</span>
                                </label>
                                <input
                                  id="address"
                                  class="form-control form-select"
                                  type="text"
                                  placeholder="Eg. No 34 Lobito Crescent, Wuse 2"
                                  v-model="address"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="false" class="col-md-6">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label class="select-label" for="state"
                                  >Country
                                  <span class="required_asterix">*</span></label
                                >
                                <select
                                  @change="getStates()"
                                  id="country"
                                  class="form-control form-select"
                                  v-model="countryId"
                                >
                                  <option value="" selected disabled
                                    >--select--</option
                                  >
                                  <option
                                    v-for="(country, index) in countries"
                                    :key="index"
                                    v-bind:value="country.id"
                                    >{{ country.name }}</option
                                  >
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label class="select-label" for="state">
                                  State <span class="required_asterix">*</span>
                                </label>
                                <select
                                  @change="getCities()"
                                  id="state"
                                  class="form-control form-select"
                                  v-model="stateid"
                                >
                                  <option value="" selected disabled>
                                    --select--
                                  </option>
                                  <option
                                    v-for="(state, index) in states"
                                    :key="index"
                                    v-bind:value="state.id"
                                  >
                                    {{ state.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label class="select-label" for="city"
                                  >City
                                  <span class="required_asterix">*</span></label
                                >
                                <select
                                  id="city"
                                  class="form-control form-select"
                                  v-model="cityid"
                                >
                                  <option value="" selected disabled
                                    >--select--</option
                                  >
                                  <option
                                    v-for="(city, index) in cities"
                                    :key="index"
                                    v-bind:value="city.id"
                                    >{{ city.name }}</option
                                  >
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 details-second-section">
                      <div class="container pb-3">
                        <div class="row">
                          <div class="col-md-4" v-if="businesstype === 7">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label class="select-label" for="initial-price">
                                  Mortgage Initial Deposit
                                  <span class="required_asterix">*</span>
                                </label>
                                <input
                                  id="initial-price"
                                  class="form-control form-select"
                                  type="text"
                                  placeholder="E.g 1,500,000"
                                  v-model="initialprice"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="businesstype === 7">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label
                                  class="select-label"
                                  for="installment-payment"
                                >
                                  Installment Payment
                                  <span class="required_asterix">*</span>
                                </label>
                                <input
                                  id="installment-payment"
                                  class="form-control form-select"
                                  type="text"
                                  placeholder="E.g 1,500,000"
                                  v-model="installmentpayment"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4" v-else>
                            <div class="form-wrap">
                              <div class="form-group">
                                <label for="price" class="select-label">
                                  Annual Rent Rate
                                  <span class="required_asterix">*</span>
                                </label>
                                <input
                                  id="price"
                                  class="form-control form-select"
                                  type="number"
                                  placeholder="E.g 1,500,000"
                                  v-model="price"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="businesstype === 6">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label
                                  class="select-label"
                                  for="discounted-price"
                                >
                                  Discounted Price
                                  <span class="required_asterix">*</span>
                                </label>
                                <input
                                  id="discounted-price"
                                  class="form-control form-select"
                                  type="text"
                                  placeholder="E.g 1,000,000"
                                  v-model="discountedprice"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4" v-if="businesstype === 7">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label class="select-label" for="mop">
                                  Mode of payment
                                  <span class="required_asterix">*</span>
                                </label>
                                <select
                                  id="mop"
                                  class="form-control form-select"
                                  v-model="modeofpayment"
                                  @change="test"
                                >
                                  <option value="" selected disabled>
                                    --select--
                                  </option>
                                  <option
                                    v-for="(mop, index) in modeOfPayments"
                                    :key="index"
                                    v-bind:value="mop"
                                  >
                                    {{ mop }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-wrap">
                              <div class="form-group">
                                <label class="select-label" for="denomination">
                                  Denomination
                                  <span class="required_asterix">*</span>
                                </label>
                                <select
                                  id="denomination"
                                  class="form-control form-select"
                                  v-model="denomination"
                                >
                                  <option value="" selected disabled>
                                    --select--
                                  </option>
                                  <option
                                    :disabled="denomination === 'USD'"
                                    v-for="(denomination,
                                    index) in denominations"
                                    :key="index"
                                    v-bind:value="denomination"
                                  >
                                    {{ denomination }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 details-btn-wrap">
                      <div class="detail-btn-holder">
                        <button
                          @click.prevent="pevBtnSecd()"
                          class="btn  prev-1"
                        >
                          Back
                        </button>
                        <button
                          @click.prevent="nextBtnSecd()"
                          class="btn next-1"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page">
                <div class="property-documents">
                  <div class="details-body">
                    <div class="col-md-8 details-header">
                      <div class="details-header-text">
                        <p>Property Documents & Media</p>
                      </div>
                    </div>
                    <div class="col-md-8 details-first-section">
                      <div class="container pb-3">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-wrap">
                              <p class="upload-header">
                                Upload Evidence of ownership
                              </p>
                              <div class="upload-file-holder">
                                <div class="file-holder">
                                  <img
                                    @click="$refs.evi.click()"
                                    src="../assets/images/uploadfile.svg"
                                    alt="file"
                                  />
                                  <input
                                    ref="evi"
                                    type="file"
                                    name="evidence"
                                    id="evi"
                                    class="d-none"
                                    @change="evidenceOfOwnership($event)"
                                  />
                                  <p>
                                    Drag and drop or
                                    <span class="browse">browse</span> your
                                    files
                                  </p>
                                </div>
                              </div>
                              <p class="file-type">
                                Supported file formats PNG, JPEG, GIF or PDF
                              </p>
                              {{ eviownship }}
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-wrap">
                              <p class="upload-header">
                                Upload Property Images
                              </p>
                              <div class="upload-file-holder">
                                <div class="file-holder">
                                  <img
                                    @click="$refs.propp.click()"
                                    src="../assets/images/uploadfile.svg"
                                    alt="file"
                                  />
                                  <!-- <img@click="$refs.files.click()"
                                  src="../assets/images/uploadfile.svg" alt=""> -->
                                  <input
                                    ref="propp"
                                    type="file"
                                    name="evidence"
                                    id="propps"
                                    class="d-none"
                                    multiple
                                    @change="uploadThePropertyImage()"
                                  />
                                  <!-- <input
                                    ref="files"
                                    type="file"
                                    name="evidence"
                                    id="evi"
                                    class="d-none"
                                    multiple
                                    @change="uploadPropertyImages"
                                  /> -->
                                  <p>
                                    Drag and drop or
                                    <span class="browse">browse</span> your
                                    files
                                  </p>
                                </div>
                              </div>

                              <p class="file-type">
                                Supported file formats PNG, JPEG, GIF or PDF
                              </p>
                              <p>{{ propertyimages }}</p>
                              <!-- <div class="field pb-3">
                                  <div v-for="(file, index) in files" :key="index" :class="`level  ${file.invalidMessage && 'has-text-danger'} ${!file.invalidMessage && 'has-text-success'} `">
                                    <div class="level-left">
                                      <div class="level-item">
                                        {{ file.name }}
                                        <span v-if="file.invalidMessage">&nbsp;- {{file.invalidMessage}}</span>
                                      </div>
                                    </div>
                                    <div class="level-right">
                                      <div class="level-item">
                                       <a @click.prevent="files.splice(index, 1); uploadFiles.splice(index, 1)" href="" class="delete"><p class="remove-file">X</p></a>
                                      </div>
                                    </div>
                                  </div>
                              </div> -->
                            </div>
                          </div>
                        </div>
                        <div class="tm">
                          <div class="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="tearm-condition"
                              :value="acceptedterms"
                              v-model="acceptedterms"
                            />
                            <label
                              class="custom-control-label facility-check"
                              for="tearm-condition"
                              >I hereby declare that the information presented
                              therein are true and correct and that i will be
                              liable for any false information given
                              thereof.</label
                            >
                            <!-- {{acceptedterms}} -->
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8 details-btn-wrap">
                      <div class="detail-btn-holder">
                        <button
                          @click.prevent="prevBtnThird()"
                          class="btn  prev-2"
                        >
                          Back
                        </button>
                        <button
                          @click.prevent="addProperties()"
                          class="btn next-2"
                        >
                          Finish
                          <i
                            class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                            v-if="loader"
                          ></i>
                        </button>
                        <!-- <button
                          @click.prevent="
                            addProperties();
                          "
                          class="btn next-2"
                        >
                          Finish
                          <i
                            class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                            v-if="loader"
                          ></i>
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page slidepage">
                <div class="property-fininish">
                  <div class="details-body">
                    <div class="col-md-8 details-header">
                      <div class="details-header-text">
                        <p>Finish</p>
                      </div>
                    </div>
                    <div class="col-md-8 details-first-section">
                      <div class="container pb-3">
                        <div class="good-finished">
                          <img
                            src="../assets/images/check-good.svg"
                            alt="finished"
                          />
                        </div>
                        <p class="got-covered-text">
                          .... We Got You Covered !
                        </p>
                      </div>
                    </div>
                    <div class="col-md-8 details-btn-wrap">
                      <div class="start-btn-holder">
                        <button @click.prevent="prevBtnFourth()" class="btn">
                          Start again
                        </button>
                      </div>
                    </div>
                    <!-- <button
                      @click.prevent="prevBtnFourth()"
                      class="btn btn-secondary prev-3"
                    >
                      Prev
                    </button>
                    <button
                      @click.prevent="submitBtn()"
                      class="btn btn-success"
                    >
                      Start again
                    </button> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import _ from "lodash";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "AddPropertyForSale",
  components: {
    Header,
    FooterMain
  },
  data() {
    return {
      currentStep: null,
      scrollPosition: null,
      features: false,
      title: "",
      // propertyTypeId: "",
      // propertySubTypeId: "",
      proprtyTypes: "",
      proprtySubTypes: "",
      businessTypes: "",
      // businessType: "",
      businesstype: "",
      type: "",
      subtype: "",
      bathrooms: "",
      bedrooms: "",
      toilets: "",
      squarefootage: "",
      duration: 3,
      facilitiesTypes: "",
      facilities: [],
      newfacility: "",
      countryId: 161,
      countries: "",
      states: "",
      stateid: "",
      cityid: "",
      cities: "",
      modeofaquisition: "",
      mode: "",
      propStatus: "",
      status: "",
      address: "",
      price: "",
      installmentpayment: "",
      initialprice: "",
      discountedprice: "",
      developer: "",
      denomination: "",
      denominations: "",
      modeOfPayments: "",
      modeofpayment: "",
      evidenceofownership: "",
      files: [],
      uploadFiles: [],
      message: "",
      error: false,
      propertyimages: "",
      propimgname: "",
      eviownship: "",
      file: "",
      file2: [],
      images: [],
      fileSizeInMb: "",
      fileName: "",
      formData: "",
      thefileprop: "",
      acceptedterms: "",
      description: "",
      parking: "",
      id: "",
      loader: false
    };
  },
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    // this.getUserProfile(this.id);
    window.addEventListener("scroll", this.updateScroll);
    this.getPropertyTypes();
    this.getBusinessType();
    this.getModeOfAquisition();
    this.getPropertyStatus();
    this.getFacilities();
    this.getCountries();
    this.getStates();
    this.getDenimination();
    this.getModeOfPayments();
    // this.getStates
  },
  methods: {
    // test() {
    //   console.log(this.modeofpayment);
    // },
    backtohome: function() {
      this.$router.push("/shelta");
    },
    addNewFacility: async function() {
      this.loader = true;
      this.$store.commit("setApiWarning", "Adding new feature...");
      try {
        await Api.postRequest(`addfeatureandfacility`, {
          facility: this.newfacility,
          userid: this.id
        })
          .then(res => {
            this.getFacilities();
            this.newfacility = "";
            this.$store.commit("setApiSuccess", "Success");
          })
          .catch(err => {
            this.loader = false;
            console.log(err);
            this.$store.commit("setApiFailed", err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    addProperties: async function() {
      this.loader = true;
      if (this.evidenceofownership === "") {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Add evidence of owner");
      }
      if (this.propertyimages === "") {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Add property image");
      }
      if (this.acceptedterms === false || this.acceptedterms === "") {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Thick terms and condition");
      }
      try {
        await Api.postRequest(`addpropertyforsale`, {
          title: this.title,
          businesstype: this.businesstype,
          modeofpayment: this.modeofpayment,
          denomination: this.denomination,
          price: parseInt(this.price),
          initialprice: parseInt(this.initialprice),
          discountedprice: parseInt(this.discountedprice),
          installmentpayment: parseInt(this.installmentpayment),
          address: this.address,
          cityid: parseInt(this.cityid),
          stateid: parseInt(this.stateid),
          modeofaquisition: this.modeofaquisition,
          status: this.status,
          duration: this.duration,
          developer: this.developer,
          squarefootage: this.squarefootage,
          bathrooms: this.bathrooms,
          toilets: this.toilets,
          bedrooms: this.bedrooms,
          type: this.type,
          subtype: this.subtype,
          userid: this.id,
          evidenceofownership: this.evidenceofownership,
          acceptedterms: this.acceptedterms,
          // city: this.city,
          // state: this.state,
          facilities: this.facilities,
          parking: this.parking,
          description: this.description,
          propertyimages: this.propertyimages
          // images: this.images
        })
          .then(res => {
            // console.log("post response:", res);
            this.loader = false;
            if (res.data.success) {
              this.evidenceOfOwnership = "";
              this.eviownship = "";
              this.propertyimages = "";
              this.propimgname = "";
              this.nextBtnThird();
              // this.countries = res.data.property;
              // this.getStates();
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loader = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    uploadPropertyImages: async function(event) {
      const files = this.$refs.files.files;
      this.uploadFiles = [...this.uploadFiles, ...files];
      this.files = [
        ...this.files,
        ..._.map(files, file => ({
          name: file.name,
          size: file.size,
          type: file.type,
          invalidMessage: this.validate(file)
        }))
      ];
      // console.log("Another files", files);
      this.uploadFileProperty();
    },
    validate: function(file) {
      // console.log("Checking validiation". file);
      const MAX_SIZE = 200000;
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif"
      ];
      if (file.size > MAX_SIZE) {
        return `Max size: ${MAX_SIZE / 1000}Kb`;
      }
      if (!allowedTypes.includes(file.type)) {
        return "Not an image";
      }
      if (file.type === "") {
        return "No file was selected";
      }
      return "";
    },
    uploadFileProperty: async function() {
      this.$store.commit("setApiWarning", "Uploading....");
      _.forEach(this.uploadFiles, file => {
        if (this.validate(file) === "") {
          this.formData = new FormData();
          this.formData.append("file", file);
        }
      });
      try {
        await Api.postRequest(`uploadpropertyimage`, this.formData)
          .then(res => {
            // console.log("Uploaded file response:", res);
            this.files = [];
            this.uploadFiles = [];
            if (res.data.error) {
              // this.fileSizeType = true;
              return this.$store.commit("setApiFailed", res.data.error);
            } else if (res.data.success) {
              // this.images = res.data.url;
              this.images = res.data.filename;
              this.$store.commit("setApiSuccess", res.data.success);
              // console.log("Uploaded Sucessfully");
            }
          })
          .catch(err => {
            this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    // uploadPropertyImages: async function(event) {
    //   this.file2 = this.$refs.propp.files;
    //   console.log("file to upload", this.file2);
    //   if (!this.file2) {
    //     this.loader2 = false;
    //     return this.$store.commit("setApiFailed", "Nothing was selected!");
    //   }
    //   Array.from(this.file2).forEach(file => {
    //     if (
    //     (file.type === "image/jpeg")||
    //     (file.type === "image/jpg") ||
    //     (file.type === "image/png") ||
    //     (file.type === "image/gif")
    //   ) {
    //     this.loader2 = true;
    //     console.log("accepted file");
    //   } else {
    //     this.loader2 = false;
    //     return this.$store.commit(
    //       "setApiFailed",
    //       "Please select an image file"
    //     );
    //   }
    //   });
    //   // if (
    //   //   (this.file2.type === "image/jpeg")||
    //   //   (this.file2.type === "image/jpg") ||
    //   //   (this.file2.type === "image/png") ||
    //   //   (this.file2.type === "image/gif")
    //   // ) {
    //   //   this.loader2 = true;
    //   //   console.log("accepted file");
    //   // } else {
    //   //   this.loader2 = false;
    //   //   return this.$store.commit(
    //   //     "setApiFailed",
    //   //     "Please select an image file"
    //   //   );
    //   // }
    //   const formData = new FormData();
    //   const sizeInMB = (this.file2.size / (1024 * 1024)).toFixed(3);
    //   for (const i = 0; i < this.$refs.propp.files.length; i++) {
    //     let file = this.$refs.propp.files[i];
    //     this.fileSizeInMb = sizeInMB;
    //   console.log("File Size", sizeInMB);
    //   console.log("form data", formData);
    //   formData.append("file", file[i]);
    //   }
    //   if (sizeInMB > 8.3) {
    //     this.fileSizeType = true;
    //     this.loader2 = false;
    //     return this.$store.commit(
    //       "setApiFailed",
    //       "File size too large, should be less than 1.3MB"
    //     );
    //   } else {
    //     try {
    //       await Api.postRequest(`uploadpropertyimage`, formData)
    //         .then((res) => {
    //           console.log("File upload", res);
    //           this.loader2 = false;
    //           if (res.data.error) {
    //             console.log("error while uploading:");
    //             // this.fileSizeType = true;
    //             return this.$store.commit("setApiFailed", res.data.error);
    //           } else if (res.data.success) {
    //             this.file2= res.data.url;
    //             this.images = this.file2;
    //             // this.guarantorPassportName = res.data.filename;
    //             this.images = res.data.filename;
    //             console.log("File name", this.images);
    //             this.$store.commit("setApiSuccess", "success");
    //             console.log(
    //               "Passport File name",

    //             );
    //             this.$store.commit(
    //               "setApiSuccess",
    //               "Guarantor passport updated"
    //             );
    //             console.log("Uploaded Sucessfully");
    //             console.log("Success file size", this.fileSizeInMb);
    //           }
    //         })
    //         .catch((err) => {
    //           this.loader2 = false;
    //           console.log(err);
    //         });
    //     } finally {
    //       console.log("Closed connection");
    //     }
    //   }
    // },
    uploadThePropertyImage: async function(event) {
      this.$store.commit("setApiWarning", "Uploading...");
      this.file = this.$refs.propp.files[0];
      this.propertyimages = this.file.name;
      if (!this.file) {
        this.loader2 = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.loader2 = true;
      } else {
        this.loader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select a pdf or image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.loader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be more than 5MB"
        );
      } else {
        try {
          await Api.postRequest(`uploadpropertyimage`, formData)
            .then(res => {
              this.$store.commit("setApiWarning", "");
              this.loader2 = false;
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              } else if (res.data.success) {
                this.propertyimages = res.data.filename;
                this.propimgname = res.data.filename;
                this.$store.commit("setApiSuccess", "success");
              }
            })
            .catch(err => {
              this.loader2 = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    evidenceOfOwnership: async function(event) {
      this.$store.commit("setApiWarning", "Uploading...");
      this.file = this.$refs.evi.files[0];
      this.eviownship = this.file.name;
      if (!this.file) {
        this.loader2 = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif" ||
        this.file.type === "application/pdf"
      ) {
        this.loader2 = true;
      } else {
        this.loader2 = false;
        this.$store.commit("setApiWarning", "");
        return this.$store.commit(
          "setApiFailed",
          "Please select a pdf or image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      formData.append("file", this.file);
      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.loader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be more than 5MB"
        );
      } else {
        try {
          await Api.postRequest(`uploadevidenceofownership`, formData)
            .then(res => {
              this.$store.commit("setApiWarning", "");
              this.loader2 = false;
              if (res.data.error) {
                // this.fileSizeType = true;
                return this.$store.commit("setApiFailed", res.data.error);
              } else if (res.data.success) {
                this.file = "";
                this.evidenceofownership = res.data.url;
                this.eviownship = res.data.filename;
                this.evidenceofownership = res.data.filename;
                this.$store.commit(
                  "setApiSuccess",
                  "Evidence of ownnership updated"
                );
                console.log("Uploaded Sucessfully");
                console.log("Success file size", this.fileSizeInMb);
              }
            })
            .catch(err => {
              this.loader2 = false;
              console.log(err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    getCountries: async function() {
      this.$store.commit("setApiWarning", "Loading countries...");
      try {
        await Api.getRequest(`fetchallcountries`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.countries = res.data.countries;
              // this.getStates();
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getCities: async function() {
      const id = this.stateid;
      this.$store.commit("setApiWarning", "Loading cities");
      try {
        await Api.getRequest(`fetchcitiesbystateid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.cities = res.data.cities;
              // console.log("State id", this.stateid);
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getDenimination: async function() {
      try {
        await Api.getRequest(`fetchdenomination`)
          .then(res => {
            if (res.data.success) {
              this.denominations = res.data.denomination;
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getModeOfPayments: async function() {
      try {
        await Api.getRequest(`fetchmodeofpaymentforsales`)
          .then(res => {
            if (res.data.success) {
              this.modeOfPayments = res.data.modeofpayment;
              const { modeOfPayments } = this;
              // console.log("State id", this.stateid);
              // return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getStates: async function() {
      const id = this.countryId;
      this.$store.commit("setApiWarning", "Loading state...");
      try {
        await Api.getRequest(`fetchstatesbycountryid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.states = res.data.states;
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getFacilities: async function() {
      try {
        await Api.getRequest(`fetchfacilities`)
          .then(res => {
            if (res.data.success) {
              this.facilitiesTypes = res.data.facilities;
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getBusinessType: async function() {
      try {
        await Api.getRequest(`fetchbusinesstypesduringsale`)
          .then(res => {
            if (res.data.success) {
              this.businessTypes = res.data.businesstype;
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getSubTypeInfo: function(event) {
      this.getPropertySubTypes();
    },
    getPropertyStatus: async function() {
      try {
        await Api.getRequest(`fetchpropertystatuses`)
          .then(res => {
            if (res.data.success) {
              this.propStatus = res.data.propertystatus;
              // return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getModeOfAquisition: async function() {
      try {
        await Api.getRequest(`fetchmethodofaquisition`)
          .then(res => {
            if (res.data.success) {
              this.mode = res.data.modeofaquisition;
              // return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getPropertySubTypes: async function() {
      const id = this.type;
      this.$store.commit("setApiWarning", "Loading property subtype...");
      try {
        await Api.getRequest(`fetchpropertysubtypesbytypeid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.proprtySubTypes = res.data.propertysubtypes;
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getPropertyTypes: async function() {
      this.$store.commit("setApiWarning", "Loading property types...");
      try {
        await Api.getRequest(`fetchpropertytypes`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.proprtyTypes = res.data.propertytypes;
              return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    firstNextBtn: function() {
      if (
        this.title === "" ||
        this.businesstype === "" ||
        this.type === "" ||
        this.subtype === "" ||
        (this.bathrooms === "" && this.type != "4") ||
        (this.bedrooms === "" && this.type != "4") ||
        (this.toilets === "" && this.type != "4") ||
        // this.bathrooms === "" ||
        // this.bedrooms === "" ||
        // this.toilets === "" ||
        this.squarefootage === "" ||
        this.duration === "" ||
        this.status === "" ||
        this.modeofaquisition === "" ||
        this.description === "" ||
        (this.parking === "" && this.type != "4") ||
        (this.developer === "" && this.type != "4")
      ) {
        return this.$store.commit(
          "setApiFailed",
          "Fill all required form before going to next"
        );
      } else {
        const slidePage = document.querySelector(".slidepage");
        // const firstNextBtn = document.querySelector(".nextBtn");
        // const prevBtnSecd = document.querySelector(".prev-1");
        // const nextBtnSecd = document.querySelector(".next-1");
        // const prevBtnThird = document.querySelector(".prev-2");
        // const nextBtnThird = document.querySelector(".next-2");
        // const prevBtnFourth = document.querySelector(".prev-3");
        // const submitBtn = document.querySelector(".submit");
        const preogressText = document.querySelectorAll(".step p");
        const progresCheck = document.querySelectorAll(".step .check");
        const bullet = document.querySelectorAll(".step .bullet");

        const max = 4;
        let current = 1;

        slidePage.style.marginLeft = "-25%";
        bullet[current - 1].classList.add("active");
        preogressText[current - 1].classList.add("active");
        progresCheck[current - 1].classList.add("active");
        current += 1;
        const elmnt = document.getElementsByClassName("page");
        elmnt[0].scrollIntoView();
      }
    },
    nextBtnSecd: function() {
      if (
        this.countryId === "" ||
        this.stateid === "" ||
        this.cityid === "" ||
        this.address === "" ||
        (this.businesstype === 7 && this.initialprice === "") ||
        (this.businesstype === 7 && this.installmentpayment === "") ||
        (this.businesstype === 7 && this.modeofpayment === "") ||
        (this.businesstype === 6 && this.discountedprice === "") ||
        (this.businesstype !== 7 && this.price === "") ||
        this.denomination === ""
      ) {
        return this.$store.commit(
          "setApiFailed",
          "Fill all required form before going next"
        );
      } else {
        const slidePage = document.querySelector(".slidepage");
        // const firstNextBtn = document.querySelector(".nextBtn");
        // const prevBtnSecd = document.querySelector(".prev-1");
        // const nextBtnSecd = document.querySelector(".next-1");
        // const prevBtnThird = document.querySelector(".prev-2");
        // const nextBtnThird = document.querySelector(".next-2");
        // const prevBtnFourth = document.querySelector(".prev-3");
        // const submitBtn = document.querySelector(".submit");
        const preogressText = document.querySelectorAll(".step p");
        const progressCheck = document.querySelectorAll(".step .check");
        const bullet = document.querySelectorAll(".step .bullet");

        const max = 4;
        let current = 1;

        slidePage.style.marginLeft = "-50%";
        bullet[current - 0].classList.add("active");
        preogressText[current - 0].classList.add("active");
        progressCheck[current - 0].classList.add("active");
        current += 1;
        const elmnt = document.getElementsByClassName("page");
        elmnt[0].scrollIntoView();
      }
    },
    nextBtnThird: function() {
      _.forEach(this.uploadFiles, file => {
        if (this.validate(file) === "") {
          this.thefileprop = file;
          this.formData = new FormData();
          this.formData.append("file", file);
          // console.log("Multiple file check", file);
        }
      });
      if (this.evidenceofownership === "") {
        return this.$store.commit("setApiFailed", "Select file");
      } else {
        const slidePage = document.querySelector(".slidepage");
        // const firstNextBtn = document.querySelector(".nextBtn");
        // const prevBtnSecd = document.querySelector(".prev-1");
        // const nextBtnSecd = document.querySelector(".next-1");
        // const prevBtnThird = document.querySelector(".prev-2");
        // const nextBtnThird = document.querySelector(".next-2");
        // const prevBtnFourth = document.querySelector(".prev-3");
        // const submitBtn = document.querySelector(".submit");
        const preogressText = document.querySelectorAll(".step p");
        const progressCheck = document.querySelectorAll(".step .check");
        const bullet = document.querySelectorAll(".step .bullet");

        const max = 4;
        let current = 1;

        slidePage.style.marginLeft = "-75%";
        bullet[current + 1].classList.add("active");
        preogressText[current + 1].classList.add("active");
        progressCheck[current + 1].classList.add("active");
        current += 1;
        const elmnt = document.getElementsByClassName("page");
        elmnt[0].scrollIntoView();
      }
    },
    pevBtnSecd: function() {
      const slidePage = document.querySelector(".slidepage");
      // const firstNextBtn = document.querySelector(".nextBtn");
      // const prevBtnSecd = document.querySelector(".prev-1");
      // const nextBtnSecd = document.querySelector(".next-1");
      // const prevBtnThird = document.querySelector(".prev-2");
      // const nextBtnThird = document.querySelector(".next-2");
      // const prevBtnFourth = document.querySelector(".prev-3");
      // const submitBtn = document.querySelector(".submit");
      const preogressText = document.querySelectorAll(".step p");
      const progressCheck = document.querySelectorAll(".step .check");
      const bullet = document.querySelectorAll(".step .bullet");
      // const preogressText = document.querySelectorAll(".step p");
      // const progressCheck = document.querySelectorAll(".step .check");
      // const bullet = document.querySelectorAll(".step .bullet");

      const max = 4;
      let current = 1;

      slidePage.style.marginLeft = "0%";
      bullet[current - 1].classList.remove("active");
      preogressText[current - 1].classList.remove("active");
      progressCheck[current - 1].classList.remove("active");
      current -= 1;
      const elmnt = document.getElementsByClassName("page");
      elmnt[0].scrollIntoView();
    },
    prevBtnThird: function() {
      const slidePage = document.querySelector(".slidepage");
      const firstNextBtn = document.querySelector(".nextBtn");
      // const prevBtnSecd = document.querySelector(".prev-1");
      // const nextBtnSecd = document.querySelector(".next-1");
      // const prevBtnThird = document.querySelector(".prev-2");
      // const nextBtnThird = document.querySelector(".next-2");
      // const prevBtnFourth = document.querySelector(".prev-3");
      // const submitBtn = document.querySelector(".submit");
      const preogressText = document.querySelectorAll(".step p");
      const progressCheck = document.querySelectorAll(".step .check");
      const bullet = document.querySelectorAll(".step .bullet");
      //  const preogressText = document.querySelectorAll(".step p");
      //   const progressCheck = document.querySelectorAll(".step .check");
      //   const bullet = document.querySelectorAll(".step .bullet");

      const max = 4;
      let current = 1;

      slidePage.style.marginLeft = "-25%";
      bullet[current - 0].classList.remove("active");
      preogressText[current - 0].classList.remove("active");
      progressCheck[current - 0].classList.remove("active");
      current -= 1;
      const elmnt = document.getElementsByClassName("page");
      elmnt[0].scrollIntoView();
    },
    prevBtnFourth: function() {
      const slidePage = document.querySelector(".slidepage");
      // const firstNextBtn = document.querySelector(".nextBtn");
      // const prevBtnSecd = document.querySelector(".prev-1");
      // const nextBtnSecd = document.querySelector(".next-1");
      // const prevBtnThird = document.querySelector(".prev-2");
      // const nextBtnThird = document.querySelector(".next-2");
      // const prevBtnFourth = document.querySelector(".prev-3");
      // const submitBtn = document.querySelector(".submit");
      const preogressText = document.querySelectorAll(".step p");
      const progressCheck = document.querySelectorAll(".step .check");
      const bullet = document.querySelectorAll(".step .bullet");
      // const preogressText = document.querySelectorAll(".step p");
      // const progresCheck = document.querySelectorAll(".step .check");
      // const bullet = document.querySelectorAll(".step .bullet");

      const max = 4;
      let current = 1;

      slidePage.style.marginLeft = "0%";
      bullet[current - 4].classList.remove("active");
      preogressText[current - 4].classList.remove("active");
      progressCheck[current - 4].classList.remove("active");
      current -= 1;
      const elmnt = document.getElementsByClassName("page");
      elmnt[0].scrollIntoView();
      this.title === "",
        this.businesstype === "",
        this.modeofpayment === "",
        this.denomination === "",
        this.price === "",
        this.address === "",
        this.cityid === "",
        this.stateid === "",
        this.modeofaquisition === "",
        this.status === "",
        this.duration === "",
        this.squarefootage === "",
        this.bathrooms === "",
        this.toilets === "",
        this.bedrooms === "",
        this.type === "",
        this.subtype === "",
        this.id === "",
        this.evidenceofownership === "",
        this.acceptedterms === "",
        this.facilities === "",
        this.parking === "",
        this.description === "",
        this.propertyimages === "";
    },
    submitBtn: function() {
      // const slidePage = document.querySelector(".slidepage");
      // const firstNextBtn = document.querySelector(".nextBtn");
      // const prevBtnSecd = document.querySelector(".prev-1");
      // const nextBtnSecd = document.querySelector(".next-1");
      // const prevBtnThird = document.querySelector(".prev-2");
      // const nextBtnThird = document.querySelector(".next-2");
      // const prevBtnFourth = document.querySelector(".prev-3");
      // const submitBtn = document.querySelector(".submit");
      const preogressText = document.querySelectorAll(".step p");
      const progressCheck = document.querySelectorAll(".step .check");
      const bullet = document.querySelectorAll(".step .bullet");

      const max = 4;
      let current = 1;

      bullet[current + 2].classList.remove("active");
      preogressText[current + 2].classList.remove("active");
      progressCheck[current + 2].classList.remove("active");
      current += 1;
      const elmnt = document.getElementsByClassName("page");
      elmnt[0].scrollIntoView();
    }
  },
  updateScroll() {
    this.scrollPosition = window.scrollY;
  },
  computed: {
    pregressBar() {
      const slidePage = document.querySelector(".slidepage");
      const firstNextBtn = document.querySelector(".nextBtn");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

// $gray:  #E5E5E5;
// $gray2: #808080;
// $blue:  #2183DD;
// $green: #009900;
// $white: #FFFFFF;

// File upload section
.has-text-danger {
  color: #dc3545 !important;
  font-size: 10px;
}
.has-text-success {
  color: #28a745 !important;
}
.level {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}
.remove-file {
  background: $primary;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50px;
  padding-top: 0.219rem;
  color: $whiteText;
}
// File upload section ends

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background-color: #efefee;
}

// Nav bar begins
.b2d {
  padding-left: 0.5rem;
  position: relative;
  top: 0.2rem;
}
.ms {
  padding-right: 0.5rem;
  position: relative;
  top: 0.2rem;
}
.dashboard-icon {
  background: $primary;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: inline-block;
}
.dashboard-icon > img {
  padding-top: 0.5rem;
  width: 60%;
}

.navLinks {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  // display: flex;
  align-items: center;
  color: $secondary;
}
.navLinks:first-child {
  padding-top: 1rem;
}
.nav-top {
  background: $whiteText;
  // padding: 1.4rem;
}

.logo-div {
  margin: auto;
  // width: 12rem;
  height: 7rem;
  height: 4rem;
  position: relative;
  bottom: 1rem;
}
.logo-blueDiv-main > img {
  width: 14.3016105417vw;
}
.shelta-img-div > img {
  width: 6.7115666179vw;
}
.shelta-img-div {
  position: absolute;
  top: 26%;
  left: 26%;
  cursor: pointer;
}

.logo-div > img:first-child {
  position: relative;
  width: 18.301610541727673vw;
  margin: auto;
  top: 0;
  right: 32%;
}
.logo-div > img:nth-child(2) {
  width: 8.711566617862372vw;
  // width: 6.5rem;
  position: absolute;
  bottom: -53%;
  left: 7%;
}
// Nav bar ends

.body-wrap {
  padding-top: 6rem;
}
.container-fluid .progress-bar2 {
  display: flex;
  margin: 40px 0;
}

.container-fluid .progress-bar2 .step {
  position: relative;
  text-align: center;
  width: 100%;
}

.progress-bar2 .step p {
  font-size: 18px;
  font-weight: 500;
  color: $secondary;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  align-items: center;
  margin-bottom: 8px;
  transition: 0.2s;
}
// .progress-bar2 .step p:nth-child(2) {
//   font-size: 18px;
//   font-weight: 500;
//   color: $secondary;
//   font-family: Gotham;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 13px;
//   line-height: 12px;
//   align-items: center;
//   margin-bottom: 8px;
//   transition: 0.2s;
// }

.progress-bar2 .step p.active {
  color: $primary;
}

// .progress-bar2 .step p:nth-child(2).active {
//   color: $primary;
// }

.progress-bar2 .step:last-child .bullet::before,
.progress-bar2 .step:last-child .bullet::after {
  display: none;
}

.progress-bar2 .step .bullet::before,
.progress-bar2 .step .bullet::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 22.7vw;
  background: #d4d4d4;
  bottom: 11px;
  left: 20.2px;
}

.progress-bar2 .step .bullet.active::after {
  background: $primary;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate 0.3s linear forwards;
}

@keyframes animate {
  100% {
    transform: scaleX(1);
  }
}

.progress-bar2 .step .bullet.active {
  border-color: $primary;
  background: $primary;
}

.progress-bar2 .step .bullet {
  position: relative;
  height: 25px;
  width: 25px;
  border: 2px solid #c4c4c4;
  display: inline-block;
  border-radius: 50%;
  transition: 0.2s;
}

.progress-bar2 .step .bullet span {
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.progress-bar2 .step .bullet.active span {
  display: none;
}

.progress-bar2 .step .check {
  display: none;
  position: absolute;
  left: 50%;
  top: 63%;
  font-size: 15px;
  transform: translate(-50%, -50%);
}
.progress-bar2 .step .check.active {
  display: block;
  color: $whiteText;
}

.container-fluid .form-outter {
  width: 100%;
  overflow: hidden;
}
.form-outter form {
  display: flex;
  width: 400%;
}
.form-outter form .page {
  width: 25%;
  transition: margin-left 0.3s ease-in-out;
}
// Wizard section ends

// Details form part begins
.title-wrap {
  padding: 2rem 0rem 1rem 0rem;
}

.required_asterix {
  color: #ea0000;
  padding-left: 5px;
  padding-top: 9px;
  font-size: 24px;
}
.rls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 51, 234, 0.2);
  margin: auto;
  overflow-y: auto;
  height: 66px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.details-header {
  margin: auto;
  background: $primary;
  height: 3rem;
  border-radius: 20px 20px 0px 0px;
}

.details-header-text > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: $whiteText;
  margin-bottom: 0;
  padding-top: 0.7rem;
  padding-left: 2rem;
}

.details-first-section {
  background: #ffffff;
  margin: 1rem auto;
}

.form-wrap {
  padding: 1.5rem 1rem 1rem 1rem;
}
.form-select {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  color: $secondary !important;
  font-family: Gotham !important;
  font-style: italic !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 13px !important;
  box-shadow: none !important;
  // align-items: center;
}

.select-label {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: $secondary;
}

.propertyBtn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4rem;
}
.propertyBtn > button {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  align-items: center;
  width: 134px;
  height: 45px;
  color: $whiteText;
  background-color: #2fc643;
  // padding-top: 1rem;
  border-color: none;
}

.newfacility_div {
  display: flex;
}
.new_feature {
  box-shadow: none;
  outline: 0;
  font-family: Gotham;
  color: $secondary !important;
  font-weight: normal;
  font-size: 12px;
}

.newfacility_btn {
  color: #ffffff;
  background: $primary;
  font-size: 12px;
  font-family: Gotham;
  font-weight: normal;
  font-style: normal;
  margin-left: 0.5rem;
  display: flex;
  height: 31px;
}
// Details second section begins
.details-second-section {
  background: #ffffff;
  margin: 1rem auto;
  border-radius: 0px 0px 20px 20px;
}
// Details second section ends

// Details button
.details-btn-wrap {
  margin: 1rem auto;
}
.detail-btn-holder {
  display: flex;
  justify-content: space-between;
}
.detail-btn-holder > button:first-child {
  background: #c4c4c4;
  border-radius: 5px;
  width: 134px;
  height: 45px;
}
.detail-btn-holder > button:nth-child(2) {
  width: 134px;
  height: 45px;
  background: #2fc643;
  border-radius: 5px;
  color: $whiteText;
}

// Facility check
.feature-text {
  display: flex;
  padding-bottom: 1rem;
  margin-left: 1rem;
  cursor: pointer;
  width: fit-content;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 17px;
  align-items: center;
  color: $secondary;
}

.fa-arrow-down {
  color: $secondary;
  margin-left: 10px;
}

.arrow_up {
  transform: rotate(180deg) !important;
  // animation: bounce 2s infinite !important;
}

.bounce {
  animation: bounce 2s infinite;
}
@keyframes bounce {
  // 50% {
  //   transform: translateY(-10px);
  // }
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.bounceUp {
  animation: bounce 2s infinite;
}
@keyframes bounceUp {
  100%,
  80%,
  50%,
  20%,
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-5px);
  }
  40% {
    transform: translateY(-10px);
  }
}

.check-box-facility {
  display: flex;
  flex-wrap: wrap;
}
.facility-check {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
  // display: flex;
  align-items: center;
  color: $secondary;
}
.facility-checkbox {
  padding-top: 4rem;
  padding-bottom: 2rem;
}
.checkbox-wrap {
  padding-left: 1rem;
  text-align: start;
}
// Facility check

// File upload section
.upload-header {
  padding-top: 2rem;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  align-items: flex-end;
  color: $secondary;
}
.upload-file-holder {
  margin: 3rem auto;
  margin-bottom: 1.5rem;
  width: 285px;
  height: 202px;
  background: #ffffff;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
}
.file-holder {
  padding-top: 2rem;
}
.file-holder > img:first-child {
  cursor: pointer;
}
.file-holder > p:nth-child(3) {
  padding-top: 1rem;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  align-items: center;
  color: rgba(0, 0, 0, 0.9);
}
.browse {
  color: $primary;
  text-decoration: underline;
}

.file-type {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  align-items: center;
  color: #b93623;
}
.tm {
  text-align: start;
  padding: 1rem 3rem 0 3rem;
}
// File upload section

// We got you covered
.good-finished {
  padding: 7rem 0 2rem 0;
}
.good-finished > img {
  width: 5rem;
}
.got-covered-text {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  color: $primary;
  padding-bottom: 7rem;
}
.start-btn-holder > button {
  width: 134px;
  height: 45px;
  background: $primary;
  // line-height: 46px;
  border-radius: 5px;
  color: $whiteText !important;
}
// we got you covered ends

input[type="radio"]:checked:after {
  background-color: #6886f0 !important;
  // border: 2px solid white;
}

@media screen and (max-width: 320px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .logo-blueDiv-main > img {
    width: 33.301611vw;
  }
  .shelta-img-div > img {
    width: 15.711567vw;
  }
}
@media screen and (max-width: 360px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .logo-blueDiv-main > img {
    width: 33.301611vw;
  }
  .shelta-img-div > img {
    width: 15.711567vw;
  }
}
@media only screen and (max-width: 599px) {
  .logo-blueDiv-main > img {
    width: 33.301611vw;
  }
  .shelta-img-div > img {
    width: 15.711567vw;
  }
  .nav-top {
    height: 3rem;
  }
  .navLinks {
    padding-bottom: 2rem;
  }
  .logo-div {
    right: 0.5rem;
  }
  .newfacility_btn {
    display: inline-block;
    margin-top: 15px;
  }
  .newfacility_div {
    display: block;
  }
  .progress-bar2 .step .bullet::before,
  .progress-bar2 .step .bullet::after {
    left: 22px;
    width: 16.7vw;
  }
  .detail-btn-holder > button:first-child {
    width: 86px;
    height: 36px;
  }
  .file-type {
    line-height: 15px;
  }
  .upload-file-holder {
    width: 240px;
  }
  .detail-btn-holder > button:nth-child(2) {
    width: 86px;
    height: 36px;
  }
  .propertyBtn {
    justify-content: center !important;
  }
  .propertyBtn > button {
    width: 102px;
    height: 36px;
  }
  .tm {
    padding: 3rem 0 0 0;
  }
  .details-header-text > p {
    font-size: 15px;
    display: block;
    padding-left: 0;
  }
  .propertyBtn {
    margin-bottom: 4rem;
  }
  .feature-text {
    display: block;
    text-align: left;
    font-size: 18px;
  }
  .check-box-facility {
    display: block;
  }
  .property-holder {
    padding: 0;
  }
  .details-first-section {
    padding: 0;
  }
  .form-wrap {
    padding: 1.5rem 0.5rem 1rem 0.5rem;
  }
  .facility-checkbox {
    padding-top: 3rem;
  }
}
</style>
